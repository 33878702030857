import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Home.css';

const Home = () => {
  const [showMainContent, setShowMainContent] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [hoveredVideo, setHoveredVideo] = useState(''); 
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth > 768 && window.innerWidth <= 1024); // Ανίχνευση tablet
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth > 768 && window.innerWidth <= 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const videoDuration = 11900;
    const timer = setTimeout(() => {
      setShowMainContent(true);
    }, videoDuration);

    return () => clearTimeout(timer);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleOutsideClick = (e) => {
    if (menuOpen && !e.target.closest('.menu-container') && !e.target.closest('.menu-icon')) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, [menuOpen]);

  const handleMobileClick = (videoName, link) => {
    if (isMobile || isTablet) {
      setHoveredVideo(videoName);
      setTimeout(() => {
        navigate(link);
      }, 4000);
    }
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>Mindgen Advertising | Home</title>
        <meta name="description" content="Mindgen Group offers expert communication and marketing solutions, covering digital marketing, PR, and sales strategies for businesses." />
        <meta name="keywords" content="Mindgen, Communication, Marketing, PR, Digital Marketing, Sales Strategy" />
        <meta property="og:title" content="Mindgen Advertising | Home" />
        <meta property="og:description" content="Expert communication and marketing solutions for businesses." />
        <meta property="og:image" content="/assets/og-image-home.jpg" />
      </Helmet>

      <div className={`home-landing-video-container ${showMainContent ? 'fade-out-intro' : ''}`}>
        <video autoPlay muted loop playsInline className="home-landing-video">
          {isMobile ? (
            <>
              <source src="/assets/intro-mob.webm" type="video/webm" />
              <source src="/assets/intro-mob.mp4" type="video/mp4" />
            </>
          ) : isTablet ? (
            <>
              <source src="/assets/intro-tab.webm" type="video/webm" />
              <source src="/assets/intro-tab.mp4" type="video/mp4" />
            </>
          ) : (
            <>
              <source src="/assets/land.webm" type="video/webm" />
              <source src="/assets/land.mp4" type="video/mp4" />
            </>
          )}
          Your browser does not support the video tag.
        </video>
      </div>

      <div className={`home-main-content-container ${showMainContent ? 'fade-in' : ''}`}>
        <Link to="/" className="home-logo-link">
          <img src="/assets/mindgen.svg" alt="MindGen Logo" className="home-logo" />
        </Link>

        <video autoPlay muted loop playsInline className="home-main-video">
          {isMobile ? (
            <>
              <source src="/assets/m-mob.webm" type="video/webm" />
              <source src="/assets/m-mob.mp4" type="video/mp4" />
            </>
          ) : isTablet ? (
            <>
              <source src="/assets/m-tab.webm" type="video/webm" />
              <source src="/assets/m-tab.mp4" type="video/mp4" />
            </>
          ) : (
            <>
              <source src="/assets/m.webm" type="video/webm" />
              <source src="/assets/m.mp4" type="video/mp4" />
            </>
          )}
        </video>

        {/* Hover Videos */}
        {hoveredVideo === 'wise' && (
          <video autoPlay muted loop playsInline className="hover-video">
            {isMobile ? (
              <>
                <source src="/assets/wise-mob.webm" type="video/webm" />
                <source src="/assets/wise-mob.mp4" type="video/mp4" />
              </>
            ) : isTablet ? (
              <>
                <source src="/assets/wise-tab.webm" type="video/webm" />
                <source src="/assets/wise-tab.mp4" type="video/mp4" />
              </>
            ) : (
              <>
                <source src="/assets/wise.webm" type="video/webm" />
                <source src="/assets/wise.mp4" type="video/mp4" />
              </>
            )}
          </video>
        )}

        {hoveredVideo === 'sets' && (
          <video autoPlay muted loop playsInline className="hover-video">
            {isMobile ? (
              <>
                <source src="/assets/sets-mob.webm" type="video/webm" />
                <source src="/assets/sets-mob.mp4" type="video/mp4" />
              </>
            ) : isTablet ? (
              <>
                <source src="/assets/sets-tab.webm" type="video/webm" />
                <source src="/assets/sets-tab.mp4" type="video/mp4" />
              </>
            ) : (
              <>
                <source src="/assets/sets.webm" type="video/webm" />
                <source src="/assets/sets.mp4" type="video/mp4" />
              </>
            )}
          </video>
        )}

        {hoveredVideo === 'trap' && (
          <video autoPlay muted loop playsInline className="hover-video">
            {isMobile ? (
              <>
                <source src="/assets/trap-mob.webm" type="video/webm" />
                <source src="/assets/trap-mob.mp4" type="video/mp4" />
              </>
            ) : isTablet ? (
              <>
                <source src="/assets/trap-tab.webm" type="video/webm" />
                <source src="/assets/trap-tab.mp4" type="video/mp4" />
              </>
            ) : (
              <>
                <source src="/assets/trap.webm" type="video/webm" />
                <source src="/assets/trap.mp4" type="video/mp4" />
              </>
            )}
          </video>
        )}

        {/* SVG κουμπιά */}
        <a
          href="https://mindwise.gr"
          target="_blank"
          rel="noopener noreferrer"
          onMouseEnter={() => !isMobile && setHoveredVideo('wise')}
          onMouseLeave={() => !isMobile && setHoveredVideo('')}
          onClick={() => handleMobileClick('wise', 'https://mindwise.gr')}
        >
          <img src="/assets/mindwise.svg" alt="Mindwise" className="svg-button mindwise" />
        </a>

        <a
          href="https://mindtrapadvertising.gr"
          target="_blank"
          rel="noopener noreferrer"
          onMouseEnter={() => !isMobile && setHoveredVideo('trap')}
          onMouseLeave={() => !isMobile && setHoveredVideo('')}
          onClick={() => handleMobileClick('trap', 'https://mindtrapadvertising.gr')}
        >
          <img src="/assets/mindtrap.svg" alt="Mindtrap" className="svg-button mindtrap" />
        </a>

        <a
          href="https://mindsets.gr"
          target="_blank"
          rel="noopener noreferrer"
          onMouseEnter={() => !isMobile && setHoveredVideo('sets')}
          onMouseLeave={() => !isMobile && setHoveredVideo('')}
          onClick={() => handleMobileClick('sets', 'https://mindsets.gr')}
        >
          <img src="/assets/mindsets.svg" alt="Mindsets" className="svg-button mindsets" />
        </a>

        {/* Burger Menu */}
        <div className="menu-icon-container">
          <img
            src={menuOpen ? '/assets/close.svg' : '/assets/bmenu.svg'}
            alt="Menu"
            className="menu-icon"
            onClick={toggleMenu}
          />
        </div>

        {/* Slide-in Menu */}
        <div className={`menu-container ${menuOpen ? 'menu-open' : ''}`}>
          <img
            src="/assets/close.svg"
            alt="Close"
            className="menu-close-icon"
            onClick={toggleMenu}
          />
          <div className="menu-links-container">
            <Link to="/about" className="menu-link slide-in-item" onClick={toggleMenu}>
              ABOUT US
            </Link>
            <Link to="/contact" className="menu-link slide-in-item" onClick={toggleMenu}>
              CONTACT
            </Link>
          </div>

          {/* Social media icons */}
          <div className="menu-socials-container">
            <a
              href="https://www.facebook.com/Mindtrapadvertising"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/assets/fb.svg" alt="Facebook" className="menu-social-icon" />
            </a>
            <a
              href="https://www.instagram.com/mindgenadvertising/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/assets/insta.svg" alt="Instagram" className="menu-social-icon" />
            </a>
            <a
              href="https://www.tiktok.com/@mindgenadvertising"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/assets/tiktok.svg" alt="TikTok" className="menu-social-icon" />
            </a>
            <a
              href="https://www.linkedin.com/company/mindgenadvertising/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/assets/linkedin.svg" alt="LinkedIn" className="menu-social-icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
