import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Menu.css'; // Χρησιμοποιεί κοινό CSS για το μενού

const Menu = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Κλείσιμο μενού αν κλικάρουμε εκτός του μενού
  const handleOutsideClick = (e) => {
    if (menuOpen && !e.target.closest('.menu-container') && !e.target.closest('.menu-icon')) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, [menuOpen]);

  return (
    <>
      {/* Burger Menu Icon */}
      <div className="menu-icon-container">
        <img
          src={menuOpen ? '/assets/close.svg' : '/assets/bmenu.svg'}
          alt="Menu"
          className="menu-icon"
          onClick={toggleMenu}
        />
      </div>

      {/* Slide-in Menu */}
      <div className={`menu-container ${menuOpen ? 'menu-open' : ''}`}>
        <img
          src="/assets/close.svg"
          alt="Close"
          className="menu-close-icon"
          onClick={toggleMenu}
        />
        <div className="menu-links-container">
          <Link to="/about" className="menu-link slide-in-item" onClick={toggleMenu}>
            ABOUT US
          </Link>
          <Link to="/contact" className="menu-link slide-in-item" onClick={toggleMenu}>
            CONTACT
          </Link>
        </div>

        {/* Social media icons */}
        <div className="menu-socials-container">
          <a
            href="https://www.facebook.com/Mindtrapadvertising"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/fb.svg" alt="Facebook" className="menu-social-icon" />
          </a>
          <a
            href="https://www.instagram.com/mindgenadvertising/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/insta.svg" alt="Instagram" className="menu-social-icon" />
          </a>
          <a
            href="https://www.tiktok.com/@mindgenadvertising"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/tiktok.svg" alt="TikTok" className="menu-social-icon" />
          </a>
          <a
            href="https://www.linkedin.com/company/mindgenadvertising/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/linkedin.svg" alt="LinkedIn" className="menu-social-icon" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Menu;
