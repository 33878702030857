// Contact.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import emailjs from 'emailjs-com'; // Για την αποστολή email
import Menu from '../../components/Menu'; // Εισαγωγή του κοινού μενού
import './Contact.css';

const Contact = () => {

  useEffect(() => {
    document.title = "Mindgen Advertising | Contact";
  }, []);

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send('service_c6xs3bn', 'template_22z5xxl', formData, 'fAZDl5W-SgFVQ3CMN')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

    // Clear form
    setFormData({
      fullName: '',
      email: '',
      message: ''
    });
  };

  return (
    <div className="contact-container">

<Helmet>
        <title>Mindgen Advertising | Contact</title>
        <meta name="description" content="Contact Mindgen Group for expert communication and marketing strategies. Let's work together to grow your business." />
        <meta name="keywords" content="Mindgen, Contact, Communication, Marketing, Digital Strategy, PR" />
        <meta property="og:title" content="Mindgen Advertising | Contact" />
        <meta property="og:description" content="Get in touch with us for all your communication and marketing needs." />
        <meta property="og:image" content="/assets/og-image-contact.jpg" />
      </Helmet>
      <Link to="/" className="contact-logo-link">
        <img src="/assets/mindgen.svg" alt="MindGen Logo" className="contact-logo" />
      </Link>

      {/* Εισαγωγή του μενού */}
      <Menu />

      {/* Περιεχόμενο σελίδας */}
      <div className="contact-content">
        <div className="contact-info">
          <p>15, Kastorias Str.<br />Gerakas, Athens, 15344</p>
          <p>+30 210 6840 182</p>
          {/* Ενσωμάτωση Google Map */}
          <iframe
            src="https://www.google.com/maps/embed?pb=YOUR_GOOGLE_MAPS_EMBED_LINK"
            width="300"
            height="300"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            title="Google Maps"
          ></iframe>
        </div>

        <div className="contact-form">
  <h2>Contact us</h2>
  <form onSubmit={handleSubmit}>
    <input
      type="text"
      name="fullName"
      placeholder="Full Name"
      value={formData.fullName}
      onChange={handleChange}
      required
    />
    <input
      type="email"
      name="email"
      placeholder="E-mail"
      value={formData.email}
      onChange={handleChange}
      required
    />
    <textarea
      name="message"
      placeholder="Tell us more about"
      value={formData.message}
      onChange={handleChange}
      required
      rows="4"
    ></textarea>
    
    <button type="submit">Submit</button>
  </form>
</div>

      </div>

      <footer>
        <p>© Mindgen 2024</p>
      </footer>
    </div>
  );
};

export default Contact;
