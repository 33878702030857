import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './About.css';
import Menu from '../../components/Menu';

const About = () => {
  useEffect(() => {
    document.title = 'Mindgen Advertising | About';
  }, []);

  const [revealContent, setRevealContent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRevealContent(true); // Ανοίγει το κείμενο μετά από μια μικρή καθυστέρηση
    }, 200); // 200ms καθυστέρηση για να ξεκινήσει το reveal
  }, []);

  return (
    <div className="about-container">

      <Helmet>
        <title>Mindgen Advertising | About</title>
        <meta name="description" content="Learn more about Mindgen Group, a leader in communication strategies, digital marketing, PR, and business development solutions." />
        <meta name="keywords" content="Mindgen, About, Digital Marketing, Communication Strategies, PR, Events" />
        <meta property="og:title" content="Mindgen Advertising | About" />
        <meta property="og:description" content="Learn more about our services and history in the world of digital marketing and communication." />
        <meta property="og:image" content="/assets/og-image-about.jpg" />
      </Helmet> 
      
      <Link to="/" className="about-logo-link">
        <img src="/assets/mindgen.svg" alt="MindGen Logo" className="about-logo" />
      </Link>

      <Menu />

      {/* Βίντεο στο φόντο */}
      <div className="about-background-overlay">
        <video autoPlay muted loop playsInline className="about-background-video">
          <source src="/assets/about.webm" type="video/webm" />
          <source src="/assets/about.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* The Idea Generator */}
      <h1 id="idea-generator-title" className={`about-title title-slide-left`}>
        The Idea Generator
      </h1>

      <div id="idea-generator-group" className="about-content">
        <p className="about-paragraph paragraph-slide-left">
          Founded back in 2011, we have grown into one of the largest and best-known event
          management group of companies. With 15 years of experience, we guarantee an unparallel
          enthusiasm for delivering every single event, as if it were the most important event in the
          world.
        </p>
        <p className="about-paragraph paragraph-slide-left">
          At MINDGEN our goal is to combine 360° communication services including Marketing strategy & Consulting, Creative & Content, Media & Data, as well as Platform & Technology services tailored to your individual needs - from expert teams working together as one single source.
        </p>
        <p className="about-paragraph paragraph-slide-left">
          Through our houses of creative communication functions – MINDTRAP, MINDWISE and MINDSETS we not only seek the best possible and commercially successful ideas, but also the optimal implementation path aiming to spark strong emotions. Our unique goal is that your outstanding brand remains etched on the memory of your clients, partners, employees and consumers as innovative, positive and market leading.
        </p>
      </div>

      {/* Born Innovative */}
      <h2 id="born-innovative-title" className={`about-title title-slide-left`}>
        Born Innovative
      </h2>

      <div id="born-innovative-group" className="about-content">
        <p className="about-paragraph paragraph-slide-left">
          Innovative. Interactive. Independent. Integrated. This is the MINDGEN Group. The expert teams working at our creative houses combine these skills and capabilities to achieve one goal: to transform your needs into the best communication strategy. Our experienced people combine all marketing and creative disciplines of special agencies under one roof, offering fully integrated services.  
        </p>
        <p className="about-paragraph paragraph-slide-left">
          Because every single time your brand is the most important brand in the world.
        </p>
      </div>

      {/* The Mindgen Companies */}
      <h2 className="about-companies-title title-slide-left">
        The Mindgen Companies
      </h2>

      <div className="about-logos logo-slide-left">
        <a href="https://mindwise.gr" target="_blank" rel="noopener noreferrer">
          <img src="/assets/wiselogo.svg" alt="Mindwise" className="about-logo-item" />
        </a>
        <a href="https://mindtrapadvertising.gr" target="_blank" rel="noopener noreferrer">
          <img src="/assets/traplogo.svg" alt="Mindtrap" className="about-logo-item" />
        </a>
        <a href="https://mindsets.gr" target="_blank" rel="noopener noreferrer">
          <img src="/assets/setslogo.svg" alt="Mindsets" className="about-logo-item" />
        </a>
      </div>
    </div>
  );
};

export default About;
